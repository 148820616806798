<template>
  <div>
    <slot v-if="$slots.preview" name="preview"/>
    <div v-if="$slots.edit">
      <div class="formPart flix-list-group-item flix-active">
        <span class="flix-pull-right" v-if="form[index].type !== 'terms' && form[index].subtype !== 'email'">
          <a href="#" @click.prevent="setDelete()" class="flix-btn flix-btn-default flix-btn-xs flix-text-danger"><flixIcon :id="'bin'" /></a>
        </span>
        <a href="#" @click.prevent="setMoveUp()" class="flix-btn flix-btn-default flix-btn-xs"><flixIcon :id="'chevron-up'" /></a>
        <a href="#" @click.prevent="setMoveDown()" class="flix-btn flix-btn-default flix-btn-xs"><flixIcon :id="'chevron-down'" /></a>
        <a v-if="form[index].type !== 'terms'" href="#" :class="[{'flix-btn-default': !edit},{'flix-btn-info': edit}]" class="flix-btn flix-btn-xs" @click.prevent="edit = !edit"><flixIcon :id="'cogwheel'" /></a>
      </div>
      <transition name="flixFadeIn">
        <div class="formPart flix-list-group-item" :key="edit">
          <slot name="edit" v-if="!edit"/>
          <formEditor :form="form" :index="index" v-if="edit"/>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    formEditor () { return import('@/components/formBuilder/editor/index') }
  },
  props: { form: Array, index: Number, setDelete: Function, setMoveDown: Function, setMoveUp: Function },
  data () {
    return {
      edit: false
    }
  },
  methods: {
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-list-group-item.flix-active
    background-color: lightgrey
    border: darkgrey
</style>
